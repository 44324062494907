import React ,{ Component } from 'react';
import { Route, Redirect } from "react-router-dom";
import AuthenticationService from './service/AuthenticateService'

class AuthenticatedRoute extends Component {
  render() {
      if (AuthenticationService.isUserLoggedIn()) {
        //console.log("AuthenticatedRoute::user logged in");
          return <Route {...this.props} />
      } else {
          return <Redirect to="/login" />
      }

  }
}

export default AuthenticatedRoute;
