import '../css/loginmodalpopup.css';
import React, { Component } from "react";

import { Modal, Button } from "react-bootstrap";
import { GoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../googleutils/refreshToken';
const gClientId = '989064674711-ihqe4b7sbvl8u3lu4f8jp68hvuo9hget.apps.googleusercontent.com';

class LoginModal extends Component {

    constructor(props){
        super(props);
        this.state={
            show : false
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }
    componentDidMount = (props) => {
         // //console.log(this.props);
          document.addEventListener('contextmenu', (e) => {
              e.preventDefault();
          });
      };
handleClose = () => {this.setState({show:false})};
handleShow(){
    this.setState({show:true});
}

        render(){
              return (
                      <>
                        <Button variant="primary" onClick={this.handleShow}>
                        Login
                        </Button>

                        <Modal
                        show={this.state.show}
                        onHide={this.handleClose}
                        backdrop={false}
                        keyboard={false}
                        style={{opacity:1,}}
                        >
                        <Modal.Header closeButton onClick={this.handleClose.bind(this)} style={{color:"red"}}>
                        <Modal.Title id="example-custom-modal-styling-title">
                             <label >
                                                    <GoogleLogin
                                                    clientId={gClientId}
                                                    buttonText="Log in with Google"
                                                    onSuccess={this.props.onSuccess}
                                                    onFailure={this.props.onFailure}
                                                    cookiePolicy={'single_host_origin'}
                                                    style={{ marginTop: '100px' }}
                                                    isSignedIn={true}
                                                    />
                                                </label>
                        
                        </Modal.Title>
                        </Modal.Header>
                       
                        <Modal.Body >
                      
                        <div >

                        <div className="auth-inner ">
                        <div className="form-group ">



                        </div>
                         
                         <form onSubmit={this.props.loginClicked}>
                                        <h3 className="textSignIn">Sign In</h3>

                                        <div className="form-group">
                                            <label>User Name</label>
                                            <input type="text" name="username" value={this.props.state.username} onChange={this.props.handleChange}  className="form-control" placeholder="Enter user Name"  />
                                        </div>

                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" name="password" value={this.props.state.password} onChange={this.props.handleChange} className="form-control" placeholder="Enter password" />
                                        </div>

                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                            </div>
                                        </div>

                                        <button type="submit" className="btn btn-primary btn-block" >Submit</button>
                                        <p className="forgot-password text-right">
                                            Forgot <a href="#">password?</a>
                                        </p>
                                  </form>

                        
                        </div>
                        </div> 
                        
                    

                        </Modal.Body>
                        <Modal.Footer>
                       
                     
                        </Modal.Footer>
                        </Modal>
                        </>

            );
        }
    }

export default LoginModal;