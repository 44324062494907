import React, { Component } from 'react';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';


export class AddEmployee extends Component {
    empRow = {
        id: '',
        name: ''
      
      };

      constructor(props) {
        super(props);
        this.state = {
            item: this.empRow
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      async componentDidMount() {
       
         
      
      }
      handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
      }


 
    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;

        await fetch('http://localhost:8086/addEmployee/', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(item),
        });
        this.props.history.push('/fetchEmpDetails');


      }
      
    render() {
        const {item} = this.state;
        const title = <h2>{item.id ? 'Edit Employee' : 'Add Employee'}</h2>;
        return (
           <div>
        
            <Container>
              {title}
              <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                  <Label for="id">id</Label>
                  <Input type="text" name="id" id="id" value={item.id || ''}
                         onChange={this.handleChange} autoComplete="id"/>
                </FormGroup>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input type="text" name="name" id="name" value={item.name || ''}
                         onChange={this.handleChange} autoComplete="name"/>
                </FormGroup>
                <FormGroup>
                  <Button color="primary" type="submit">Save</Button>{' '}
                 
                </FormGroup>
              </Form>
            </Container>
          </div>
        );
    }
}

export default AddEmployee;
