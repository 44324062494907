import React from 'react'
import EmpService from '../service/EmpService';
import {Navbar,Nav,NavDropdown,Form,FormControl,Button} from 'react-bootstrap';
 class FetchAllEmployees extends React.Component {
    constructor(props){
        super(props);
         this.state={isLoaded: false,employees:[] };
    }
   
    componentDidMount(){
        EmpService.fetchAllEmployeesListService()
       .then(response => this.setState({isLoaded:true,employees:response.data}))
        .catch(this.setState({ employees: 'Error Processing Request' }))
    }
    
    render() {
      //console.log("i am in fetchall emp's render method::",this.state.isLoaded);
      if(this.state.isLoaded){
        return (
            <div className="row justify-content-center">
                <h1 style={btnStyle}>EMPDetails Page</h1>
            
                
                <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.employees.map((emp)=>
                    <tr key={emp.id}>
                          <td >  {emp.id}  &nbsp;  </td>
                     <td >{emp.name} &nbsp; </td>
                     </tr>
                         )}
				      </tbody> 
			       
                </table>
           
        
      
               
            </div>
         );
      }else{
          return(
              <div><h2>This is FetchAllDetails Page</h2></div>
          );
      }
        
    }
}
const btnStyle={
    backgroud:'#FF8C00',
    color:'#FF8C00',
    border:'none',
    align:'center',

    cursor:'pointer',
    float:'right'
}
const itemStyle={
    backgroundColor:'#f4f4f4'
}
export default FetchAllEmployees
